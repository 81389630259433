import { inject }                   from 'aurelia-framework';
import { Statistic }                from 'modules/statistics/models/statistic';
import { CountiesRepository }       from 'modules/administration/locations/counties/services/repository';
import { StatisticTypesRepository } from 'modules/management/statistic-types/services/repository';
import { StatisticType }            from './models/statistic-type';

@inject(CountiesRepository, StatisticTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param countiesRepository
     * @param statisticTypesRepository
     */
    constructor(countiesRepository, statisticTypesRepository) {
        this.countiesRepository       = countiesRepository;
        this.statisticTypesRepository = statisticTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Statistic}
     */
    model() {
        return new Statistic().assign(this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.statistic-type',
            size:         6,
            options:      [],
            remoteSource: this.statisticTypesRepository.all.bind(this.statisticTypesRepository),
            observers:    [
                (newValue) => this.county_id.hidden = ! [StatisticType.PROCEDURES_BY_PARISH, StatisticType.RESULTS_BY_PARISH].includes(newValue),
            ],
        };

        this.date_from = {
            type:            'material-ui-date-picker',
            key:             'date_from',
            label:           'form.field.date(from)',
            size:            3,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.date_to = {
            type:            'material-ui-date-picker',
            key:             'date_to',
            label:           'form.field.date(to)',
            size:            3,
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.county_id = {
            type:         'select2',
            key:          'county_id',
            label:        'form.field.county',
            size:         6,
            hidden:       true,
            options:      [],
            remoteSource: this.countiesRepository.all.bind(this.countiesRepository),
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => viewModel.resetForm(),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.export',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-file-excel',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.type_id, this.date_from, this.date_to],
            [this.county_id],
            [this.buttons],
        ];
    }

}
