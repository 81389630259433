import { bindable, inject }     from 'aurelia-framework';
import { AppContainer }         from 'resources/services/app-container';
import { BaseFormViewModel }    from 'base-form-view-model';
import { DialogService }        from 'aurelia-dialog';
import { Downloader }           from 'resources/services/downloader';
import { FormSchema }           from 'modules/statistics/form-schema';
import { InfoDialog }           from 'resources/elements/html-elements/dialogs/info-dialog';
import { StatisticsRepository } from 'modules/statistics/services/repository';

@inject(AppContainer, DialogService, Downloader, FormSchema, StatisticsRepository)
export class ExportStatistics extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.export-statistic-data';
    @bindable formId      = 'statistics.export-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param downloader
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, downloader, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.downloader    = downloader;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultFormView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'statistics.menu.access',
        ]);
    }

    /**
     * Handles canDeactivate event
     */
    canDeactivate() {
        return true;
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData().then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Submits view form
     */
    submit() {
        this.alert = null;

        this.checkSelectedType() && this.repository.export(this.model.type_id, this.model).then(response => {
            if (response instanceof Blob) {
                this.downloader.download(response, 'estatisticas', 'xlsx');
            } else {
                this.alert = this.alertMessage(response.status, response.message, response.errors);
            }
        });
    }

    /**
     * Checks if a statistic type was selected, displaying a warning otherwise
     *
     * @returns {bool}
     */
    checkSelectedType() {
        if (! this.model.type_id) {
            this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-a-statistic-type'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        return !!this.model.type_id;
    }

}
