import { inject }           from 'aurelia-framework';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class StatisticsRepository {

    baseUrl = 'statistics';

    /**
     * Constructor
     *
     * @param httpClient
     */
    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    /**
     * Exports the requested statistic type.
     *
     * @param id
     * @param criteria
     *
     * @returns {*}
     */
    export(id, criteria) {
        return this.httpClient
            .rawPost(`${this.baseUrl}/export/${id}`, criteria)
            .then(response => response.headers.get('content-type') === 'application/json' ? response.json() : response.blob());
    }

}
