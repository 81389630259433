import {BaseModel} from 'modules/core/models/base-model';

export class Statistic extends BaseModel {

    type_id   = null;
    date_from = null;
    date_to   = null;
    county_id = null;

}
