import { BaseModel } from 'modules/core/models/base-model';

const FACILITIES_WITHOUT_DOCUMENTATION = 1;
const PROCEDURES_BY_BUILDING_TYPE      = 2;
const PROCEDURES_BY_PROCEDURE_TYPE     = 3;
const PROCEDURES_BY_RESULT             = 4;
const PROCEDURES_BY_PARISH             = 5;
const PROCEDURES_BY_REGULATION         = 6;
const RESULTS_BY_MAINTENANCE_COMPANY   = 7;
const RESULTS_BY_PARISH                = 8;

export class StatisticType extends BaseModel {

    static get FACILITIES_WITHOUT_DOCUMENTATION() {
        return FACILITIES_WITHOUT_DOCUMENTATION;
    }

    static get PROCEDURES_BY_BUILDING_TYPE() {
        return PROCEDURES_BY_BUILDING_TYPE;
    }

    static get PROCEDURES_BY_PROCEDURE_TYPE() {
        return PROCEDURES_BY_PROCEDURE_TYPE;
    }

    static get PROCEDURES_BY_RESULT() {
        return PROCEDURES_BY_RESULT;
    }

    static get PROCEDURES_BY_PARISH() {
        return PROCEDURES_BY_PARISH;
    }

    static get PROCEDURES_BY_REGULATION() {
        return PROCEDURES_BY_REGULATION;
    }

    static get RESULTS_BY_MAINTENANCE_COMPANY() {
        return RESULTS_BY_MAINTENANCE_COMPANY;
    }

    static get RESULTS_BY_PARISH() {
        return RESULTS_BY_PARISH;
    }

}
